import React from 'react'

import floating from '../../images/icon/home/Home_03.svg'
import './floating-icon.scss'
import { useIntl } from 'gatsby-plugin-react-intl'

export default function FloatingIcon (props) {
  const { link } = props
  const intl = useIntl()

  return (
    <>
      <div className='floatItem'>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <div className='green'>
            <img src={floating} />
          </div>
          <div className='textGreen'>
            <p className='text-white m-0 line-height-normal font-Spinnaker'> {intl.formatMessage({ id: 'components.floating_items.p1' })}</p>
          </div>
        </a>
      </div>
    </>
  )
}
